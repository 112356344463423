import styled from 'styled-components';

export const Info = styled.div`
    width: 100%;
    max-width: 1000px;
    //min-width: 80vw;
    //min-height: 60vh;
    position: absolute;
    top:50%;
    left:50%;
    z-index: 101;
    transform: translate3d(-50%,-50%,0);
    background: rgba(255,255,255,0.80);
    border: 2px solid #231209;
    padding: 20px;
    backdrop-filter: blur(2px);
    border-radius: 2px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    opacity:0;
    
    transition: opacity 0.5s ease-in-out;
    
    p, h1, button {
      font-size: 1rem;
      line-height: 1.3;
      
      @media (min-width: 800px) {
        font-size: 2rem;
      }

    }
`;
export const ButtonRow = styled.div`
    text-align: center;
    padding-top:2rem;
`;
export const InfoButton = styled.button`
    appearance: none;
    font-family: "Avenir LT W01 45 Book", Verdana, sans-serif;
    background: #2D6840;
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 2px;
    padding: 12px 40px;
    margin:0 auto;
    cursor: pointer;
`;
export const InfoContent = styled.div`
    max-width: 600px;
    width: 100%;
    margin:auto;
`;
