import styled from 'styled-components';
import fenster from "../assets/fenster.png";

export const WindowBg = styled.div`
    width: 100%;
    background-color: white;
    //border: 5px solid #636363;
    
    &:before {
      content:"";
      display: block;
      position: absolute;
      z-index:100;
      top:0;
      left:0;
      width:100%;
      height: 100%;
      background-image:url("${fenster}");
      background-position: center;
      background-size: auto 100%;
    }
`;
export const Window = styled.div`
    width: 100%;
    max-width: 80vh;
    margin:0 auto;
    
    @media (min-width: 1000px) {
        max-width: 640px;      
    }
`;
export const WindowWindow = styled.div`
    width: 100%;
    max-width: 100vh;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
    position: relative;
    //border: 1px solid #f0f;
`;
export const WindowContent = styled.div`
    position: absolute;
    top:0;
    left:-50%;
    width: 200%;
    height: 100%;
`;
