import Spritesheet from 'react-responsive-spritesheet';
import styled, { css, keyframes } from 'styled-components';
import spriteWichtel from "../assets/wichtel-sprites.png";

const runLeft = keyframes`
  0% {
    left: 110%;
    background-position:0 0;  
  }
  
  9.999% {
    left: 110%;
    background-position:0 0;  
  }
  10% {
    left:80%;
    background-position:0 0;
  }

  29.999% {
    left: 80%;
    background-position:0 0;
  }
  30% {
    left:65%;
    background-position:-50% 0;
  }

  49.999% {
    left: 65%;
    background-position:-50% 0;  
  }
  50% {
    left:50%;
    background-position:-75% 0;
  }

  69.999% {
    left: 50%;
    background-position:-75% 0;  
  }
  70% {
    left:35%;
    background-position:-100% 0;  
  }

  89.999% {
    left: 35%;
    background-position:-100% 0;  
  }
  90% {
    left: 20%;
    background-position:-125% 0;
  }
  99.999% {
    left: 20%;
    background-position:-125% 0;  
  }
  100% {
    left: -10%;
    background-position:-125% 0;
  }
`;
const runRight = keyframes`
  0% {
    left: -20%;
    background-position:0 0;
  }
  9.999% {
    left: -20%;
    background-position:0 0;  
  }
  10% {
    left: 10%;
    background-position:-25% 0;
  }
  29.999% {
    left: 10%;
    background-position:-25% 0;  
  }
  30% {
    left:35%;
    background-position:-50% 0;  
  }
  49.999% {
    left: 35%;
    background-position:-50% 0;  
  }
  50% {
    left:50%;
    background-position:-75% 0;
  }
  69.999% {
    left: 50%;
    background-position:-75% 0;  
  }
  70% {
    left:65%;
    background-position:-100% 0;
  }
  89.999% {
    left: 65%;
    background-position:-100% 0;
  }
  90% {
    left:80%;
    background-position:-125% 0;
  }
  99.999% {
    left: 80%;
    background-position:-125% 0;  
  }
  100% {
    left: 110%;
    background-position:-125% 0;  
  }
`;
export const WichtelSprite = styled.div`
    position: absolute;
    background-image:url("${spriteWichtel}");
    top:30%;
    left:-100%;
    //left:50%;
    //transform: translate3d(-50%,-50%,0);
    width: 20%;
    padding-bottom: 20%;
    overflow:hidden;
    height:0;
    background-size:500% 100%;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;


    ${props => (props.state === 'runLeft' ? css`
      animation: ${runLeft} 5s linear;
    ` : null)}

    ${props => (props.state === 'runRight' ? css`
      animation: ${runRight} 5s linear;
    ` : null)}

    
`;
// ${props => (props.state === 'left' ? `
//   ${runLeftAnimation}
// ` : null)}
//
// ${props => (props.state === 'right' ? `
//   transform:scaleX(-1);
//   ${runRightAnimation}
// ` : null)}


export const Wave1Sprite = styled(Spritesheet)`
    position: absolute;
    top:10%;
    left:8%;
    width: 15%;
    transition: opacity 0.5s ease-in-out;
`;

export const Wave2Sprite = styled(Spritesheet)`
    position: absolute;
    top:30%;
    left:8.5%;
    width: 10%;
    transition: opacity 0.5s ease-in-out;
`;
export const WinkSprite = styled(Spritesheet)`
    position: absolute;
    top:25%;
    right:7.5%;
    width: 10%;
    transition: opacity 0.5s ease-in-out;
`;
