import styled from 'styled-components';
import waende from "../assets/waende.png";
import baum from "../assets/baum.png";
import sofas from "../assets/sofas.png";
import ohneSchild from "../assets/wichtel-ohne-schild.png";

const Layer = styled.div`
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-position: center top;
    background-size: cover;
`;
export const Sofas = styled(Layer)`
    background-image:url("${sofas}");
`;
export const WichtelFront = styled(Layer)`
    background-image:url("${ohneSchild}");
    transition: opacity 0.5s ease-in-out;
`;
export const Baum = styled(Layer)`
    background-image:url("${baum}");
`;
export const Waende = styled(Layer)`
    background-image:url("${waende}");
    top:-20%;
`;
