import * as React from 'react';
import {useRef, useState} from 'react';
import styled from 'styled-components';

import {useDeviceOrientation} from 'the-platform';

import useMousePosition from "../utilities/mouse";
import bgVideo from '../assets/hintergrund.mp4';
import wave1 from '../assets/wave1-sprites.png';
import wave2 from '../assets/wave2-sprites.png';
import wink from '../assets/wink-sprites.png';
import {ButtonRow, Info, InfoButton, InfoContent} from "./Info";
import {Wave1Sprite, Wave2Sprite, WichtelSprite, WinkSprite} from "./Sprites";
import {Baum, Sofas, Waende, WichtelFront} from "./Backgrounds";
import {Window, WindowBg, WindowContent, WindowWindow} from "./Window";

const Main = styled.div`
    width: 100%;
    max-width: 1200px;
    margin:0 auto;
    perspective: 1000px;
`;

const BgVideo = styled.video`
    position: absolute;
    top:-10%;
    left:-10%;
    width: 120%;
    height: 120%;
`;


export const Room = () => {
    const { x, y } = useMousePosition();
    const [started, setStarted] = useState(false);
    const [gameState, setGameState] = useState("stop");
    const [touchLeft, setTouchLeft] = useState(false);
    const [touchRight, setTouchRight] = useState(false);
    const [ended, setEnded] = useState(false);

    const { alpha, beta, gamma, absolute } = useDeviceOrientation();

    const videoRef = useRef(null);

    let tilt = 0;
    let tiltY = 0;
    if(started) {
        const viewportX = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const viewportY = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

        if(gamma) { // Handy
            if(viewportX > viewportY) {// Landscape
                tilt = beta / -45;
                tiltY = (gamma + 25) / -45;
            } else {
                tilt = gamma / -90;
                tiltY = (beta - 25) / -90;
            }
        } else if(x) { // Maus
            tilt = x / viewportX - 0.5;
            tiltY =  y / viewportY - 0.5;
        }

        tiltY = tiltY / 2;

        //Limit tiltY
        tiltY = Math.max(Math.min(tiltY, 0.2), -0.2);

        tilt = Math.max(Math.min(tilt, 0.4), -0.4);

        if(!touchLeft && tilt < -0.3) {
            setTouchLeft(true);
        }

        if(!touchRight && tilt > 0.3) {
            setTouchRight(true);
        }

        if((gameState === "stop" || gameState === "right") && touchLeft && touchRight) {
            setTouchLeft(false);
            setTouchRight(false);
            setGameState('runLeft');
            setTimeout(() => {
                setGameState('waveLeft')
                setTimeout(() => {
                    if(!ended) {
                        setGameState('showEnd');
                        setEnded(true);
                    } else {
                        setGameState('left');
                    }
                }, 2000);
            }, 5000);
        }

        if(gameState === "left" && touchLeft && touchRight) {
            setTouchLeft(false);
            setTouchRight(false);
            setGameState('runRight');
            setTimeout(() => {
                setGameState('right')
            }, 5000);
        }

    }

    const start = () => {
        setStarted(true);
        // @ts-ignore
        if (window.DeviceOrientationEvent && typeof DeviceOrientationEvent.requestPermission === 'function') {
            // @ts-ignore
            DeviceOrientationEvent.requestPermission()
                .then(permissionState => {
                    if (permissionState === 'granted') {
                        window.addEventListener('deviceorientation', () => {});
                    } else {
                        alert('Ohne Zulassen der Sensoren funktioniert diese Anwendung leider nicht.');
                    }
                })
                .catch(console.error);
        } else {
            // handle regular non iOS 13+ devices
        }
    };

    return (
        <Main>
            <WindowBg >
                <Window>
                    <WindowWindow>
                        <WindowContent tilt={tilt} style={{
                            transform: `translate3d(${tilt * -50}%, ${tiltY * -50}%, 0)`,
                        }}>
                            {/*<Background/>*/}
                            <BgVideo src={bgVideo} autoPlay loop muted/>
                            <Baum style={{
                                transform: `translate3d(${tilt * 15}%, ${tiltY * 15}%, 0)`,
                            }}/>
                            <Wave1Sprite
                              image={wave1}
                              widthFrame={258}
                              heightFrame={258}
                              steps={7}
                              fps={8}
                              autoplay={true}
                              loop={true}
                              started={true}
                              style={{
                                  transform: `translate3d(${tilt * 20}%, ${tiltY * 20}%, 0)`,
                                  opacity: ['runLeft', 'runRight', 'waveLeft'].indexOf(gameState) !== -1 ? 0: 1,
                              }}
                            />
                            <WinkSprite
                              image={wink}
                              widthFrame={399}
                              heightFrame={400}
                              steps={16}
                              fps={8}
                              autoplay={true}
                              loop={true}
                              started={true}
                              style={{
                                  transform: `translate3d(${tilt * 20}%, ${tiltY * 20}%, 0)`,
                                  opacity: ['runLeft', 'runRight', 'waveLeft'].indexOf(gameState) !== -1 ? 0: 1,
                              }}
                            />
                            <Wave2Sprite
                              image={wave2}
                              widthFrame={334}
                              heightFrame={335}
                              steps={8}
                              fps={8}
                              autoplay={true}
                              loop={true}
                              started={true}
                              style={{
                                  transform: `translate3d(${tilt * 20}%, ${tiltY * 20}%, 0)`,
                                  opacity: gameState === 'waveLeft' ? 1: 0,
                              }}
                            />
                            <WichtelSprite
                              state={gameState}
                              style={{
                                  transform: `translate3d(${tilt * 20}%, ${tiltY * 20}%, 0) ${gameState==='runRight' ? 'scaleX(-1)' : 'scaleX(1)'}`,
                              }}
                            />
                            <Waende style={{
                                transform: `translate3d(${tilt * 20}%, ${tiltY * 20}%, 0)`,
                            }}/>
                            <Sofas style={{
                                transform: `translate3d(${tilt * 30}%, ${tiltY * 30}%, 0)`,
                            }}/>
                        </WindowContent>
                        <WichtelFront
                          style={{
                              opacity: gameState === 'showEnd' ? 1: 0,
                          }}
                        />
                    </WindowWindow>
                </Window>
            </WindowBg>
            <Info style={{
                opacity: started ? 0: 1,
                visibility: started ? 'hidden': 'visible',
            }}>
                <InfoContent>
                    <h1>Pssst! Leise, leise...Wichtel bei der Arbeit.</h1>
                    <p>Wer hätte es gedacht, es gibt sie doch – Weihnachtswichtel! Ihr glaubt uns nicht? Dann schaut hier durchs Fenster und seht Euch um - mit der Maus oder durch Kippen des Handys. Vielleicht entdeckt Ihr einen der fleißigen Helfer...</p>
                    <ButtonRow>
                    <InfoButton onClick={start}>Los geht's</InfoButton>
                    </ButtonRow>
                </InfoContent>
            </Info>
            <Info style={{
                opacity: gameState === 'showEnd' ? 1: 0,
                visibility: gameState === 'showEnd' ? 'visible': 'hidden',
            }}>
                <InfoContent>
                    <h1></h1>
                    <p>Das ganze ATW-Team wünscht allen Kunden, Freunden und Partnern wunderbare Weihnachtstage mit entspannten Stunden! Wir bedanken uns für die tolle Zusammenarbeit und freuen uns auf ein weiteres Jahr mit spannenden Aufgaben und Herausforderungen.</p>
                    <p>Fröhliche Weihnachten!</p>
                    <ButtonRow>
                        <InfoButton onClick={() => setGameState('left')}>Schließen</InfoButton>
                    </ButtonRow>
                </InfoContent>
            </Info>
            {/*<p>{x} / {y}</p>*/}
            {/*<p>Absolute: {absolute}</p>*/}
            {/*<p>Alpha: {alpha}</p>*/}
            {/*<p>Beta: {beta}</p>*/}
            {/*<p>Gamma: {gamma}</p>*/}
        </Main>
    );
};
