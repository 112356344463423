import * as React from "react";
import styled, {createGlobalStyle} from 'styled-components'
import {App} from "./App";
import {Hello} from "./components/Hello";
import {Room} from "./components/Room";

export interface IMainProps
{
    app: App;
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Avenir LT W01 45 Book", Verdana, sans-serif;
    font-size: 16px;
    background-color: #1A1012;
  }
`;

const Imprint = styled.p`
    width: 100%;
    max-width: 1200px;
    margin:0 auto;
    color: #C4A785;
    padding-top:20px;
`;

const ImprintLink = styled.a`
    color: #C4A785;
    text-decoration: none;
    span {
        font-weight: bold;
    }
    &:hover {
      text-decoration: underline;
    }
`;

const ImprintLogo = styled(ImprintLink)`
    text-transform: uppercase;
`;


export const Main = ({ app }: IMainProps): JSX.Element => {
    return (
        <>
            <GlobalStyle />
            <Room />
            <Imprint>Ein Gruß von <ImprintLogo href="https://www.adiwidjaja.com" target="_blank">Adiwidjaja<span>Teamworks</span></ImprintLogo><br/>
              Illustrationen von Vanessa Wloka</Imprint>
            <Imprint> <ImprintLink href="https://www.adiwidjaja.com/#/#kontakt" target="_blank">Impressum</ImprintLink> | <ImprintLink href="https://www.adiwidjaja.com/assets/Uploads/datenschutz-atw-2018.pdf" target="_blank">Datenschutz</ImprintLink></Imprint>
        </>
    );
}
